'use client';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './tokens';

const Theme: FC<LivelyInterfaces.ReactChildren> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
