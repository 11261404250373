import { DefaultTheme } from 'styled-components';
import { TextType, HeaderType, FontSizesTypes } from 'theme-definition';

export enum Colors {
  Green = 'var(--mui-palette-primary-main)',
  DarkGreen = '#148A81',
  Purple = '#7162F0',
  Red = '#C30B55',
  Blue = '#3049C9',
  LightBlue = '#C4D6FB',
  Yellow = '#FED872',
  LightGray = '#F4F5F6',
  Gray = '#939496',
  DarkGray = '#203340',
  White = '#FFFFFF',
  Black = '#000000'
}

export enum Breakpoints {
  Tablet = '@media only screen and (min-width: 760px)',
  Desktop = '@media only screen and (min-width: 1024px)',
  ExtraLarge = '@media only screen and (min-width: 1201px)'
}

export enum Spacing {
  none = '0px',
  xs = '8px',
  s = '16px',
  m = '24px',
  l = '32px',
  xl = '40px',
  xxl = '70px',
  xxxl = '120px'
}

export enum FontWeights {
  Thin = 100,
  Light = 300,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Black = 900
}

const Text: TextType = {
  xs: '12px',
  s: '14px',
  m: '18px',
  l: '20px',
  xl: '24px'
} as const;

const Headers: HeaderType = {
  s: '24px',
  m: '32px',
  l: '36px',
  xl: '40px'
} as const;

const MaxWidth = '1280px';
const FontFamily = 'Muli, sans-serif';

const FontSizes: FontSizesTypes = {
  Disclaimer: '12px',
  Text,
  Headers
};

const Tokens: DefaultTheme = {
  Colors,
  Breakpoints,
  Spacing,
  MaxWidth,
  Font: {
    Sizes: FontSizes,
    Weights: FontWeights,
    Family: FontFamily
  }
} as const;

export default Tokens;
